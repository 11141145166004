body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  @font-face {
    font-family: "AktivGrotesk Bold";
    src: url("./utils/assets/fonts/AktivGrotesk-Black.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  .content {
    width: 100%;
    justify-content: center;
    div {
      width: 100% !important;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  width: 100%;
}
